import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";
import React from 'react';
import ErrorPage from "./ErrorPage";
import { Box, Button, Grid, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export default function TrackOverview() {
    const {ArtistId, Tracks} = useLoaderData()
    const { ArtistName } = useParams();
    const navigate = useNavigate();


    if (!Tracks ) {
        return <ErrorPage errorText={"No tracks found. Might be loading...."} />
    }

    const columns = [
        {
            field: 'DisplayText',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'Split',
            headerName: 'Split (%)',
            flex: 0.3
        },
        {
            field: "ReleaseDate",
            headerName: 'Release Date',
            flex: 0.4,
            valueGetter: (value) => new Date(value).toLocaleDateString()
        },
        {
            field: 'ArtistData',
            headerName: 'Paid to Artist',
            flex: 0.4,
            valueGetter: (value) => value[ArtistId].PaidToArtist + "€"
        },
        {
            field: 'a',
            headerName: 'Open to Artist',
            flex: 0.4,
            valueGetter: (_, row) => row.ArtistData[ArtistId].PaidToArtistMissing + "€"
        },
        {
            field: 'b',
            headerName: 'Label Amount',
            flex: 0.4,
            valueGetter: (_, row) => row.ArtistData[ArtistId].LabelAmount + "€"
        },
        {
            field: 'IsDone',
            headerName: 'Is Done?',
            flex: 0.3,
            valueGetter: (value) => value ? "✅" : "❌"
        }
    ];

    return (
        <>
            <Grid container>

                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                    <Link to={"/artists/" + ArtistName + "/tracks/create"}>
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ width: "100%", height: 56, marginBottom: "10px" }}
                        >
                            <Typography >Create new Track</Typography>
                        </Button>
                    </Link>
                    <Box sx={{ height: 700, width: '100%' }} textAlign={'center'}>
                        <DataGrid
                            rows={Tracks}
                            columns={columns}
                            slots={{ toolbar: GridToolbar }}
                            getRowId={(row) => row.Id}
                            autoPageSize
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    printOptions: { disableToolbarButton: true },
                                    csvOptions: { disableToolbarButton: true },
                                },
                            }}
                                    onRowClick={(r) => navigate("/artists/" + ArtistName + "/tracks/" + r.row.Name)}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
